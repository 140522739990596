import React from "react";
import coursesStyle from './courses.module.css'
// import courses from '../../assets/images/linkedin (1).png'
// import coursesImage from '../../../../assets/images/enagrama_fbinsta.png'
import {Link, NavLink} from "react-router-dom";
import Course from '../Home/component/courses/courses'


class Courses extends React.Component{
    render() {
        return(
            // <div className={coursesStyle.courses}>
            //     <label>ԴԱՍԸՆԹԱՑՆԵՐԸ</label>
            //
            //     <div className={coursesStyle.images}>
                    <Course center={'center'}/>
                    // {/*<div>*/}
                    // {/*    <NavLink to='/courses/upcoming'>*/}
                    // {/*        /!*<img src={courses}/>*!/*/}
                    // {/*    </NavLink>*/}
                    // {/*</div>*/}
            //     </div>
            // </div>
        )
    }
}
export default Courses