import style from './eBokks.module.scss'
import  coaching1 from '../../../../assets/images/ebook_3 (1).jpg'
import  coaching2 from '../../../../assets/images/ebook_1 (1).jpg'
import book from '../../../../assets/images/Ինչ-է-քոուչինգը.pdf'
import book2 from '../../../../assets/images/Քոուչինգը-բիզնեսում.pdf'


const EBooks = ()=>{

    return(
    <div className={style.ebook_containers}>
    <div className={style.header}>ԷԼԵԿՏՐՈՆԱՅԻՆ ԳՐՔԵՐ</div>
       <div className={style.ebook_container}>
         <a href={book}   target="_blank"  title='Ի՞նչ է քոուչինգը'>
                <img src={coaching1} alt='coach'/>
          </a>
         <a href={book2}    target="_blank" title='Քոուչինգը բիզնեսում' >
              <img src={coaching2} alt='coach'/>
           </a>

                  </div>


     </div>
    )
}
export default EBooks