import React from "react";
import styles from './aboutUs.module.css'
import image from '../../assets/images/20191127190639-shutterstock-431848417-crop.jpeg'
import teamImage from '../../assets/images/pexels-fauxels-3184418 (3).jpg'


const AboutUs =()=>{
    return(
        <>
        <div className={styles.about_image}>
              <img src={image} className={styles.image} alt='coaching'/>
            <span className={styles.text}>
               ArmCoaching Coaches Development Center
            </span>
        </div>
            <div className={styles.container}>
                <div className={styles.team}>
                    <img src={teamImage}  alt='coach'/>
                </div>

                <div>
                    <div className={styles.text_container}>
                    <span className={styles.header}>ՄԵՐ ՄԱՍԻՆ</span><br/>
                    <span>
                        Քոուչը աջակից է, լավագույն ընկեր, ով թևեր է տալիս։ Մարդ, ով տալիս է հարցեր, որոնց պատասխանները
                        արդեն ունեք: Մեկը, ով միշտ պատրաստ է լսել Ձեզ՝ նպաստելով Ձեր մշտական ուսուցմանն ու զարգացմանը:</span>
                    <span>
                        Armcoaching-ը առաջին հայկական քոուչինգ ուսուցանող կազմակերպությունն է և միակ կենտրոնը, որտեղ
                        նյութերը ամբողջությամբ տրամադրվելում են հայերեն։<br/><br/>
                        Մեր նպատակներն են՝
                    </span>
                    <ul className={styles.cricle}>
                        <li>զարգացնել քոուչինգ մշակույթը Հայաստանում,</li>
                        <li>բարձրացնել քոուչինգի իրազեկությունը ու ներկայացնել հասարակությանը քոուչինգի արժեքային առաջարկը,</li>
                        <li>օգնել մարդկանց հասնել իրենց նպատակներին,</li>
                        <li>նպաստել հասարակության մշտական կրթությանն ու զարգացմանը։</li>
                    </ul>
                        <br/>
                     <span>
                       Բացի քոուչինգի ուսուցանումից ու վերապատրաստումներ կազմակերպելուց, մենք նաև իրականացնում ենք
                         անհատական և կորպորատիվ քոուչինգ սեսիաներ՝ համագործակցելով ՀՀ և միջազգային տարբեր
                         կազմակերպությունների հետ։ Այս 5 տարիների ընթացքում Armcoaching-ը հասցրել է պատրաստել և
                         վերապատրաստել շուրջ 50 քոուչերի, մեր սեմինարներին մասնակվել են 500 և ավելի քոուչինգով
                         հետաքրքրված անձինք և իրականացրել ենք ավելի քան 30 դասընթացներ և սեմինարներ, օգնել ավելի քան
                         100 անձանց բացահայտել իրենց անձնային ներուժը, իրականացրել քոուչինգ սեսիաներ 10
                         կազմակերպությունների համար։Մենք վստահ ենք, որ քոուչինգի տարածմամբ Armcoaching-ը կնպաստի
                         ՀՀ աշխատաշուկայի մոտիվացիայի և արդյունքատվության բարձրացմանը։

                    </span>
                    </div>
                </div>
            </div>
        </>
    )
}
export  default AboutUs