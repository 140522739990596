import React from "react";
import informationStyle from './information.module.css'
class Information extends React.Component{
    render() {
        return(

            <div className={informationStyle.text}>
            <div>
                <p>ՄԵՐ ՄԱՍԻՆ</p>
                <label className={informationStyle.info}> Armcoaching-ը Հայաստանում քոուչերի պատրաստման առաջին կենտրոնն
                    է, որը ոչ միայն իրականացնում է բազմաթիվ անվճար և վճարովի թրեյնինգներ, սեմինարներ, այլ նաև անցկացնում է
                    քոուչինգային սեսիաներ հատուկ կառավարիչների, ՄՌԿ մասնագետների և սեփական պոտենցյալը բացահայտել
                    ցանկացող անձանց համար։
                    </label>
            </div>
            <div>
                <p>ԳՈՐԾՈՒՆԵՈՒԹՅՈՒՆԸ</p>
                <label className={informationStyle.info}>Armcoaching-ը ստեղծվել է 2017  թվականից. այս տարիների ընթացքում մենք հասցրել ենք՝
                    <ol className={informationStyle.list}>
                        <li>Պատրաստել և վերապատրաստել շուրջ 250 քոուչերի.</li>
                        <li>ArmCoaching Hub-ի շրջանակներում  միավորել ՀՀ քոուչերին և քոուչինգով հետաքրքրված շուրջ 500 անձանց.</li>
                        <li>Բարձրացնել մի շարք կազմակերպությունների արդյունքատվությունը՝ իրականացնելով համապատասխան քոուչինգային սեսիաներ.</li>
                        <li>Օգնել ավելի քան 100 անձանց բացահայտել իրենց անձնային ներուժը.</li>
                    </ol>
                    </label>
             </div>
             <div>
                <p>ԱՌԱՔԵԼՈՒԹՅՈՒՆԸ</p>
                <label className={informationStyle.info}>Մեր նպատակը ՀՀ-ում քոուչինգի կարևորության ներկայացումն, տարածումն ու զարգացումն է։Քոուչինգը միայն
                    քոուչ մասնագետի համար չէ, այլ նաև՝ ՄՌԿ մասնագետի, ուսուցիչների, ցանկացած ղեկավար պաշտոն զբաղեցնող
                    անձանց։ Մենք հավատում ենք, որ քոուչինգի զարգացմամբ ՀՀ աշխատուժը կհասնի իր արդյունքատվության նոր
                    մակարդակի։  </label>
              </div>
            </div>

        )

    }
}
export  default Information