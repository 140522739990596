import React, {useState} from "react";
import armCoachLogo from '../../../../assets/images/logo_0003_gr_blue.png'
import navbarStyle from './navbar.module.css'
import {NavLink} from "react-router-dom";
import {useMediaQuery} from "@mui/material";
import { FiMenu } from 'react-icons/fi';


const Navbar =()=>{
    const [open,setOpen]= useState(false)
    const openNav = ()=>{
        setOpen(!open)
    }
    const small = useMediaQuery('(max-width:900px)')
        return(
            <div className={navbarStyle.navbar}>
                {!small ?
                <>
                <NavLink exact to='/' className={navbarStyle.logo}> <img src={armCoachLogo} className={navbarStyle.image} alt=''/></NavLink>
                    <nav className={navbarStyle.effect}>
                    <ul className={navbarStyle.menu}>
                    <li><NavLink exact to='/'>Գլխավոր</NavLink></li>
                    <li><NavLink exac to='/courses'>Դասընթացներ</NavLink></li>
                    <li><NavLink exact to='/about'>Մեր Մասին</NavLink></li>
                    <li><NavLink exact to='/contact'>Կապ մեզ հետ</NavLink></li>
                    </ul>
                    </nav>
                    </>
                    : <div>
                        <FiMenu size={20} style={{marginLeft:'10px',marginTop:'10px',cursor:'pointer'}} color='#241953' onClick={openNav}/>
                    </div>
                }
                {(open && small) &&
                <div className={navbarStyle.navMenu}>
                    <ul className={navbarStyle.menu2}>
                        <li><NavLink exact to='/'>Գլխավոր</NavLink></li>
                        <li><NavLink exac to='/courses'>Դասընթացներ</NavLink></li>
                        <li><NavLink exact to='/about'>Մեր Մասին</NavLink></li>
                        <li><NavLink exact to='/contact'>Կապ մեզ հետ</NavLink></li>
                    </ul>
                </div>
                }
            </div>
        )

}
export  default Navbar