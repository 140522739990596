import style from './infooCourses.module.css'
import cover from '../../../../assets/images/271920799_2805515863088490_5365865101496721002_n.png'
import React from "react";

const InfoCourses =()=>{
    return(
        <div className={style.main_container}>
            <div className={style.dedline}>Փետրվարի 15/2022 ֊ Մայիսի 22/2022</div>
            <div className={style.name}>ՈՒԶՈՒՄ ԵՍ ԴԱՌՆԱԼ ՔՈՈՒՉ #7</div>
            <div className={style.container}>
            <div className={style.image}>
                <img src={cover}/>
            </div>
            <div className={style.information1}>
               <span className={style.title}>Դասընթացի մասին</span>
                Նոր Տարի, նոր նպատակներ, նոր հնարավորություններ և նոր մասնագիտություն։
                ArmCoaching Coaches Development Center կողմից իրականացվող «Ուզո՞ւմ ես դառնալ քոուչ» 9-րդ եռամսյա դասընթացի գրանցումը բաց է։
                Ձեռք բերեք 21-րդ դարի ամենապահանջված մասնագիտությունը։
                Դասըթացի ընթացքում ուսուցանվելու է ՝
                <ul>
                    <li>Քոուչինգի հիմունքներն ու հարցադրումների արվեստը։</li>
                    <li>Քոուչինգային մոդելներն ու տեխնիկաները (coaching models and techniques)։</li>
                    <li>Անձնային աճին ու զարգացմանը նպաստող գործիքակազմեր (self-development tools)։</li>
                </ul>
            </div>

            <div className={style.information1}>
            </div>
            <div className={style.information}>
                <div className={style.cricle}></div>
                <span  className={style.title}>Դասընթացի տևողությունը-</span>
                3 ամիս է՝ շաբաթական 2 օր, երեքշաբթի, հինգշաբթի 19:00-21:00,
            </div>
            <div className={style.information}>
                <div className={style.cricle}></div>
                <span  className={style.title}>
                    Դասընթացի արժեքը֊
                    <span className={style.text}>150.000 ՀՀ դրամ,(ամսեկան՝ 50.000 ՀՀ դրամ)</span>
                    </span>
            </div>
            <div className={style.information}>
                <div className={style.cricle}></div>
                <span  className={style.title}>Գրանցման հղումը-</span>
                <a href='https://lnkd.in/djKFXMi2'> https://lnkd.in/djKFXMi2</a>
            </div>
            <div className={style.information}>
                <div className={style.cricle}></div>
                <span  className={style.title}>Դասընթացի մանրամասները-</span>
                <a href='  https://bit.ly/CoachingBrochure7'>    https://bit.ly/CoachingBrochure9</a>
            </div>
            <div className={style.information1}>
                Հիշեցնենք, որ դասընթացի վերջին 1 ամիսը տրամադրվելու է միայն պրակտիկային՝ մենթորի մասնակցությամբ։
            </div>
            </div>
        </div>

    )

}

export default InfoCourses