import React from "react";
import style from './upcoming.module.css'
import levelup from "../../../../assets/images/levelup.png";
import {IoMdDoneAll} from 'react-icons/io'

const InfoCoutselLevelup =()=>{
    return(
        <div className={style.main_container}>
            {/*<div className={style.dedline}>Հունվար  ֊ Ապրիլ</div>*/}
            <div className={style.name}>«Level UP»</div>
            <div className={style.container}>
                <div className={style.image}>
                    <img src={levelup} alt='coaching'/>
                </div>
                <div className={style.information1}>
                    <span className={style.title}>Դասընթացի մասին</span><br/>
                    Ուրախ ենք հայտարարելու առաջիկա վերապատրաստման հնարավորության մասին, որը կբարձրացնի ձեր քոուչինգային հմտությունները, գիտելիքները և կարողությունները առավել բարձր մակարդակի:
                   <p>Մենք հավատում ենք, որ այս վերապատրաստումը էապես կնպաստի ձեր աճին և հաջողությանը:</p>
                   <p>Տևողությունը՝ 3 ամբողջ օր (ուրբաթ, շաբաթ, կիրակի)։</p>
                   <p>Գտնվելու վայրը՝ կներկայացնենք առաջիկայում։</p>
                   <p>Դասընթացի ընթացքում մասնակիցները՝</p>
                    <ul>
                        <br/>
                        <li>կզարգացնեն քոուչինգ իրականացնելու հմտությունները՝ ICF կողմից մշակված կոմպետենցիաների հիման վրա,</li>
                        <li>կմշակեն Քոուչերի մուտքը դեպի աշխատաշուկա,</li>
                        <li>կզարգացնեն Քոուչի անձնային բրենդը,</li>
                        <li>կյուրացնեն Քոուչ դասընթացավարի գործիքակազմը:</li>
                    </ul>
                </div>

                <p className={style.information1}>Մասնակիցները կունենան ունենալով կատարելագործված հմտություններ և կարողություններ, կբարձրացնեն քոուչինգի մասին գիտելիքները և կհայտնաբերեն դասընթացներ վարելու և շուկա մուտք գործելու հնարավորությունները:</p>
                <p className={style.information1}>Դասընթացը նախատեսված է միայն քոուչերի համար։</p>
                <p className={style.information1}>Գրանցում՝ <a href="https://docs.google.com/forms/d/e/1FAIpQLSenzLAlTgjR99Yibkq7xT0pIRxd98DeXUWCvFCX-kPNIJgrNw/viewform?usp=send_form">Քոուչինգային դասընթացներ/ Coaching trainings</a></p>
                <p className={style.information1}>Հայտերն ընդունում ենք մինչև նույեմբերի 10</p>
                <p className={style.information1}>Հագուստի ոճը՝ Smart Casual</p>
                <p className={style.information1}>Դասընթացի ընթացքում կտրամադրվի սուրճ, թեյ, ջուր, նախաճաշ, ճաշ, ընթրիք։</p>
                <p className={style.information1}>Վկայական՝ մասնակիցները կստանան վկայական և քոուչինգային աշխատանքային ձեռնարկ:</p>
                <p className={style.information1}><b>Չեղարկման քաղաքականություն.</b> Մասնակիցները, ովքեր պետք է չեղարկեն իրենց գրանցումը դասընթացի համար, պետք է գրավոր ծանուցեն
                    <a href = "mailto: trainings@armcoaching.am">trainings@armcoaching.am</a>trainings@armcoaching.am էլ. Հասցեին։ Եթե չեղյալ հայտարարման մասին ծանուցում ստացվի սահմանված ժամկետում, մասնակիցներին հետ է վերադարձվում վերապատրաստման վճարի գումարը: Նշված վերջնաժամկետից հետո ստացված չեղարկումները ենթակա չեն վերադարձման:</p>

                <p className={style.information1}>Մենք հավատում ենք, որ ձեր անձնական և մասնագիտական զարգացման մեջ ներդրումներ կատարելը մեր ընդհանուր հաջողության կարևոր մասն է: Մենք խրախուսում ենք ձեզ օգտվել այս արժեքավոր հնարավորությունից՝ ընդլայնելու ձեր հմտություններն ու գիտելիքները:</p>
                <p className={style.information1}>Եթե ունեք հարցեր կամ կարծիք, ապա կապվել մեզ հետ՝ <a href = "mailto: coach@armcoaching.am">coach@armcoaching.am</a></p>
                <p className={style.information1}>Մենք անհամբեր սպասում ենք մեր հերթական հանդիպմանը։</p>

                {/*<div className={style.information1}>*/}
                {/*    <p>Մեկնարկ՝ հուլիս 10, 2023</p>*/}
                {/*    <p>Ավարտ՝ սեպտեմբեր 29, 2023</p>*/}
                {/*</div>*/}
                {/*<div className={style.information}>*/}
                {/*    <IoMdDoneAll size={20} color={'#241953'}/>*/}
                {/*    <span  className={style.title}>Դասընթացի տևողությունը &nbsp; - &nbsp;</span>*/}
                {/*    3 ամիս է՝ շաբաթական 2 օր, երեքշաբթի, հինգշաբթի 19:00-21:00,*/}
                {/*</div>*/}
                {/*<div className={style.information}>*/}
                {/*    <IoMdDoneAll size={20} color={'#241953'}/>*/}
                {/*    <span  className={style.title}>*/}
                {/*    Դասընթացի արժեքը &nbsp; - &nbsp;*/}
                {/*        <span className={style.text}>ամսական՝ 75.000 ՀՀ դրամ, ( 225.000 ՀՀ դրամ)</span>*/}
                {/*    </span>*/}
                {/*</div>*/}
                {/*<div className={style.information}>*/}
                {/*    <IoMdDoneAll size={20} color={'#241953'}/>*/}
                {/*    <span  className={style.title}>Գրանցման հղումը &nbsp; - &nbsp; </span>*/}
                {/*    <a href='https://forms.gle/wtvKbHPt2wK6DCcD9' target='_blank'> https://forms.gle/wtvKbHPt2wK6DCcD9</a>*/}
                {/*</div>*/}


                {/*<div className={style.information}>*/}
                {/*    <div className={style.cricle}></div>*/}
                {/*    <span  className={style.title}>Դասընթացի մանրամասները-</span>*/}
                {/*    /!*<a href='  https://bit.ly/CoachingBrochure7' target='_blank'>    https://bit.ly/CoachingBrochure9</a>*!/*/}
                {/*</div>*/}
                {/*<div className={style.information1}>*/}
                {/*    Հիշեցնենք, որ դասընթացի վերջին 1 ամիսը տրամադրվելու է միայն պրակտիկային՝ մենթորի մասնակցությամբ։*/}
                {/*</div>*/}
            </div>
        </div>

    )
}
export default InfoCoutselLevelup