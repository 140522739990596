import React from "react";
import styles from './PageNotfound.module.css'
import teamImage from '../../assets/images/404.png'


const PageNotfound =()=>{
    return(

        <div className={styles.PageNotfound_div}>
          <img src={teamImage}   alt='coach'/>
        </div>

    )
}
export  default PageNotfound