import React from "react";
import './App.css'
import Home from "./containers/Home/home";
import {BrowserRouter as Router, Route,} from 'react-router-dom';
import {Switch} from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import Navbar from "./containers/Home/component/navbar/navbar";
import Courses from "./containers/Courses/courses";
import InfoCourses from "./containers/Courses/component/info_courses/infoCourses";
import Footer from "./components/footer/footer";
import ContactUs from "./containers/Contact us/contact us";
import AboutUs from "./containers/About us/aboutUs";
import PageNotfound from "./containers/PageNotfound/PageNotfound";

import InfoCourseUpcoming from "./containers/Courses/component/info_courses/infoCourseUpcoming";
import InfoCourseLevelup from "./containers/Courses/component/info_courses/InfoCourseLevelup";
import InfoCoutselCoach from "./containers/Courses/component/info_courses/InfoCoutselCoach";

 function App() {
return(
    <Router>
        <Switch>

                <Route path="/" exact={true}>
                    <Home/>
                </Route>
                <Route path="/about" exact={true}>
                    <Navbar/>
                    <AboutUs/>
                    <Footer/>
                </Route>
                <Route path="/courses" exact={true}>
                    <Navbar/>
                    <Courses/>
                    <Footer/>
                </Route>
                <Route path="/courses/past" exact={true}>
                    <Navbar/>
                    <InfoCourses/>
                    <Footer/>
                </Route>
                <Route path="/contact" exact={true}>
                    <Navbar/>
                    <ContactUs/>
                </Route>
            <Route path='/courses/upcoming' exact={true} >
                <Navbar/>
                <InfoCourseUpcoming/>
                <Footer/>
            </Route>
            <Route path='/courses/levelup' exact={true} >
                <Navbar/>
                <InfoCourseLevelup/>
                <Footer/>
            </Route>
            <Route path='/courses/Coach' exact={true} >
                <Navbar/>
                <InfoCoutselCoach/>
                <Footer/>
            </Route>
                {/*<Route path="/careers/:slug" exact>*/}
                {/*    <Navbar/>*/}
                {/*</Route>*/}
            <Route path='/careers/:slug' exact={true} component={Navbar} />
            <Route path='*' exact={true} component={PageNotfound} />
            </Switch>
    </Router>

)
    }

    export default App