import React from "react";
import coursesStyle from './courses.module.css'
import courses from '../../../../assets/images/imgeUpcoming.jpg'
import levelup from '../../../../assets/images/levelup.png'
import Coach from '../../../../assets/images/Coach_.png'
import {Link, NavLink} from "react-router-dom";


const Courses =({center})=>{
        return(
            <div className={coursesStyle.courses}>
                {center ?
                    <label style={{width:'100%',justifyContent:'center',margin:'20px 0 50px'}}>ԴԱՍԸՆԹԱՑՆԵՐԸ</label>
:
                    <label style={{width:'80%','font-size':'30px'}}>Ծանոթացեք մեր դասընթացների մանրամասներին</label>

                }
                <div className={coursesStyle.images}  style={{'margin-top':'2%'}}>
                    <div>
                        <NavLink to='/courses/upcoming'>
                            <img src={courses} alt='քոուչինգ'/>
                        </NavLink>
                    </div>
                    <div style={{width: "26%"}}>
                        <NavLink to='/courses/levelup'>
                            <img src={levelup} alt='քոուչինգ'/>
                        </NavLink>
                    </div>
                    <div style={{width: "26%"}}>
                        <NavLink to='/courses/Coach'>
                            <img src={Coach} alt='քոուչինգ'/>
                        </NavLink>
                    </div>
                </div>






            </div>
        )

}
export default Courses