import React from "react";
import coachingImg from '../../../../assets/images/keepgrowing.png'
import slideStyle from './slide.module.css'
import Vector from "../vector";

class Slide extends  React.Component{
    render() {
        return(
            <div className={slideStyle.image}>
                <img src={coachingImg} className={slideStyle.container} alt='armCoaching'/>
            </div>
        )
    }
}
export default Slide