import React from "react";
import style from './upcoming.module.css'
import cover from "../../../../assets/images/imgeUpcoming.jpg";
import {IoMdDoneAll} from 'react-icons/io'

const InfoCourseUpcoming =()=>{
    return(
        <div className={style.main_container}>
            {/*<div className={style.dedline}>Հունվար  ֊ Ապրիլ</div>*/}
            <div className={style.name}>«Քոուչինգ՝ քո ներուժի ձեռքբերումը»</div>
            <div className={style.container}>
                <div className={style.image}>
                    <img src={cover} alt='coaching'/>
                </div>
                <div className={style.information1}>
                    <span className={style.title}>Դասընթացի մասին</span><br/>
                    ArmCoaching Coaches Development Center կողմից իրականացվող «Քոուչինգ՝ քո ներուժի ձեռքբերումը» եռամսյա դասընթացի գրանցումը բաց է։ Ձեռք բերեք 21-րդ դարի ամենապահանջված մասնագիտությունը։
                    <br/><br/>Դասըթացի ընթացքում ուսուցանվելու են՝
                    <br/>
                    <ul>
                        <br/>
                        <li>Քոուչինգային հմտություններն ու էթիկան։</li>
                        <li>Քոուչինգի հիմունքներն ու հարցադրումների արվեստը։</li>
                        <li>Քոուչինգային մոդելներն ու տեխնիկաները (coaching models and techniques)։</li>
                        <li>Անձնային աճին ու զարգացմանը նպաստող գործիքակազմեր (self-development tools)։</li>
                    </ul>
                </div>

                <div className={style.information1}>
                    <p>Մեկնարկ՝ հուլիս 10, 2023</p>
                    <p>Ավարտ՝ սեպտեմբեր 29, 2023</p>
                </div>
                <div className={style.information}>
                    <IoMdDoneAll size={20} color={'#241953'}/>
                    <span  className={style.title}>Դասընթացի տևողությունը &nbsp; - &nbsp;</span>
                    3 ամիս է՝ շաբաթական 2 օր, երեքշաբթի, հինգշաբթի 19:00-21:00,
                </div>
                <div className={style.information}>
                    <IoMdDoneAll size={20} color={'#241953'}/>
                    <span  className={style.title}>
                    Դասընթացի արժեքը &nbsp; - &nbsp;
                    <span className={style.text}>ամսական՝ 75.000 ՀՀ դրամ, ( 225.000 ՀՀ դրամ)</span>
                    </span>
                </div>
                <div className={style.information}>
                    <IoMdDoneAll size={20} color={'#241953'}/>
                    <span  className={style.title}>Գրանցման հղումը &nbsp; - &nbsp; </span>
                    <a href='https://forms.gle/wtvKbHPt2wK6DCcD9' target='_blank'> https://forms.gle/wtvKbHPt2wK6DCcD9</a>
                </div>
                {/*<div className={style.information}>*/}
                {/*    <div className={style.cricle}></div>*/}
                {/*    <span  className={style.title}>Դասընթացի մանրամասները-</span>*/}
                {/*    /!*<a href='  https://bit.ly/CoachingBrochure7' target='_blank'>    https://bit.ly/CoachingBrochure9</a>*!/*/}
                {/*</div>*/}
                <div className={style.information1}>
                    Հիշեցնենք, որ դասընթացի վերջին 1 ամիսը տրամադրվելու է միայն պրակտիկային՝ մենթորի մասնակցությամբ։
                </div>
            </div>
        </div>

    )
}
export default InfoCourseUpcoming